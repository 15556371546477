/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import {
    WarpStatusDescription,
    useWarpStatus,
} from "@/util/hooks/use_warp_status";

/**
 * Util to generate status background color
 */
export function getStatusColor(status: WarpStatusDescription) {
    switch (status) {
        case "All Systems Operational":
            return Colors["primary-green"];
        case "Partial System Outage":
            return Colors["primary-yellow"];
        case "Major System Outage":
            return Colors["primary-red"];
        default:
            return Colors["lighten-50"];
    }
}

export const StatusFlag: FunctionComponent = () => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * State
     */
    const { isFetchingStatus, statusMessage } = useWarpStatus();

    /**
     * Styles
     */
    const statusFlagContainerStyles = css({
        alignItems: "center",
        backgroundColor: Colors[theme.backgrounds.backgroundTertiary],
        borderRadius: BorderRadiuses.borderSmall,
        display: "flex",
        padding: Spacing["spacing-2"],
        width: "fit-content",
    });

    const statusFlagStyles = css({
        background: getStatusColor(statusMessage),
        borderRadius: BorderRadiuses.borderRound,
        display: "inline-block",
        height: Spacing["spacing-2"],
        marginRight: Spacing["spacing-2"],
        width: Spacing["spacing-2"],
    });

    return (
        <Button
            newWindow
            className={statusFlagContainerStyles}
            fontSize="TEXT_1"
            fontWeight="medium"
            href="https://status.warp.dev"
            variant="secondary"
        >
            <span css={statusFlagStyles} />

            {isFetchingStatus ? "Checking Status..." : statusMessage}
        </Button>
    );
};
