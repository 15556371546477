export default function imageLoader({
    format,
    quality,
    src,
    width,
}: {
    format?: string;
    quality?: number;
    src: string;
    width: number;
}) {
    const params = `w=${width},q=${quality || 50},format=${format || "auto"}`;

    const baseUrl = "https://www.warp.dev/static/image";

    const imageSources = {
        /*
         * local: {
         *     pattern: /^\/_next\/static\/media\/(.+)$/,
         *     type: "l",
         * },
         */
        remote: {
            pattern: /^https:\/\/[^/]+\.media\.strapiapp\.com\/(.+)$/,
            type: "r",
        },
    };

    for (const { pattern, type } of Object.values(imageSources)) {
        const match = src.match(pattern);
        if (match) {
            const [, path] = match;
            const prefix = type ? `${type}/` : "";
            return `${baseUrl}/${prefix}${params}/${path}`;
        }
    }

    // fallback for unmatched sources
    return src;
}
