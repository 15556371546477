import type { AnalyticsTrack } from "@/types/analytics";

export const TrackEvent = Object.freeze({
    ALL_DOWNLOADS_LINK: {
        Event: "Download Page Nav Button Clicked",
    },
    BLOG_DOWNLOAD_WARP: {
        Event: "download button clicked",
    },
    BREW_INSTALL_COMMAND: {
        Event: "brew install in header clicked ",
    },
    DOWNLOAD_BUTTON_CLICKED: {
        Event: "Download in Hero Clicked",
    },
    NAV_BAR_DOWNLOAD: {
        Event: "Download in Header Clicked",
    },
    PRICING_ENTERPRISE_CONTACT_US_BUTTON_CLICKED: {
        Event: "enterprise contact us button clicked",
    },
    PRICING_FREE_PLAN_BUTTON_CLICKED: {
        Event: "pricing page free download clicked",
    },
    PRICING_PAID_PLAN_BUTTON_CLICKED: {
        Event: "self-serve paid plan button clicked",
    },
    REQUEST_DEMO_LINK: {
        Event: "Request Demo Button Clicked",
    },
    TERMINUS_ADS_DOWNLOAD: {
        Event: "download in terminus ads clicked",
    },
    WINDOWS_WAITLIST_LINK: {
        Event: "Windows Waitlist Page Button Clicked",
    },
}) satisfies Record<string, { readonly Event: string }>;

export function getTrackEvent(
    key: keyof typeof TrackEvent,
    properties?: Record<string, string>,
): AnalyticsTrack {
    const { Event } = TrackEvent[key];
    return {
        Event,
        Properties: properties,
    };
}
