/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent, useEffect, useState } from "react";

import { StrapiAuthor, StrapiBlogTopic, StrapiMedia } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Flag } from "@/ui/atoms/flag";
import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";

import { pluralizeAuthorList } from "@/util/blog_util";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { convertToRem } from "@/util/ui_util";

export interface BlogArticleSnippet {
    Authors?: StrapiAuthor[];
    Hero_Media: StrapiMedia;
    Slug: string;
    Title: string;
    Topic?: StrapiBlogTopic;
}

export const BlogArticleCard: FunctionComponent<BlogArticleSnippet> = ({
    Topic = { Name: "All" },
    ...props
}) => {
    /**
     * Globals
     */
    const [slug, setSlug] = useState(props.Slug);
    const theme = useTypedTheme();

    useEffect(() => {
        if (window.location.pathname.includes("/blog")) {
            setSlug(`/blog/${props.Slug}`);
        } else {
            setSlug(props.Slug);
        }
    }, [props.Slug]);

    /**
     * Styles
     */

    const blogCardStyles = css({
        "&:hover": {
            backgroundColor: Colors[theme.backgrounds.backgroundTertiary],
            boxShadow: `0 0 0 .75rem ${Colors[theme.backgrounds.backgroundTertiary]}`,
        },
        alignItems: "flex-start",
        backgroundColor: "transparent",
        borderRadius: convertToRem(BorderRadiuses.borderLarge),
        display: "flex",
        flexDirection: "column",
        position: "relative",
        textDecoration: "none",
        transition: "background-color .2s, box-shadow .2s ease",
        zIndex: "1",
    });

    const blogImageStyles = css({
        marginBottom: Spacing["spacing-4"],
    });

    const blogArticleLinkStyles = css({
        whiteSpace: "wrap",
    });

    const chipStyles = css({
        border: "none",
        cursor: "pointer",
        display: "inline-flex",
    });

    /**
     * Elements
     */
    return (
        <a css={blogCardStyles} href={slug}>
            <Picture
                {...props.Hero_Media}
                alternativeText={props.Title}
                aspectRatio="2 / 1"
                className={blogImageStyles}
            />

            <Text
                className={blogArticleLinkStyles}
                fontSize="BlogArticleCardTitle"
                marginBottom="ContactCardTitleMarginBottom"
                tag="h2"
            >
                {props.Title}
            </Text>

            <Text
                fontSize="BlogArticleCardAuthors"
                marginBottom="ContactCardAuthorMarginBottom"
                themeKey="textSecondary"
            >
                By {pluralizeAuthorList(props.Authors)}
            </Text>

            {Topic.Name && (
                <Flag className={chipStyles} tag="button">
                    {Topic.Name}
                </Flag>
            )}
        </a>
    );
};
