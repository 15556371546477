/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { SerializerConfig } from "@/types/tokens/serializer";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { buildStylesByBreakpoint } from "@/util/style_util";

export type SerializerConfigKeys =
    | "AgentModeBodyRow"
    | "Article"
    | "ArticleCaption"
    | "CompareMatrixRowContent"
    | "LegacyFeatureHero"
    | "LegacyFeatureHorizontal"
    | "LegacyFeatureVerticalItem"
    | "LegacyPreFooter"
    | "Longform"
    | "PricingPopover"
    | "AgentModeBodyRow"
    | "TerminusSidebarAdSecondaryText"
    | "PrivacyList"
    | "PrivacyListSmall"
    | "StateOfCLI2023BodyText"
    | "StateOfCliKeyTakeawayDescription"
    | "UiSubheadline";

export const SerializerConfigs: {
    [key in SerializerConfigKeys]: SerializerConfig;
} = {
    AgentModeBodyRow: {
        a: {
            fontSize: "LongformTextDefault",
            interactionType: "underline-disappear",
            themeKey: "linkSecondary",
        },
        baseline: {
            shouldBalanceWrap: false,
        },
        em: {
            fontSize: "LongformTextDefault",
            fontWeight: "medium",
            tag: "em",
            themeKey: "textPrimary",
        },
        h1: {
            fontSize: "AgentModeVideoRowHeading2",
            marginTop: "AgentModeHeading2MarginTop",
            tag: "h2",
        },
        h2: {
            fontSize: "AgentModeVideoRowHeading3",
            marginTop: "AgentModeHeading3MarginTop",
            tag: "h3",
        },
        ol: {
            fontSize: "LongformTextDefault",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
        p: {
            fontSize: "LongformTextDefault",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
        strong: {
            fontSize: "LongformTextDefault",
            fontWeight: "medium",
            tag: "strong",
            themeKey: "textPrimary",
        },
        ul: {
            fontSize: "LongformTextDefault",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
    },
    Article: {
        a: {
            fontSize: "ArticleBody",
            fontWeight: "normal",
            inheritFontSize: true,
            themeKey: "linkPrimary",
        },
        baseline: {
            shouldBalanceWrap: false,
        },
        blockquote: {
            marginBottom: "ArticleQuote",
            marginTop: "ArticleQuote",
        },
        code: {
            fontSize: "ArticleCode",
            marginBottom: "ArticleLargeMargin",
            marginTop: "ArticleLargeMargin",
        },
        em: {
            fontSize: "ArticleBody",
            fontWeight: "medium",
            themeKey: "textPrimary",
        },
        h1: {
            fontSize: "ArticleHeading1",
            marginTop: "ArticleHeading1",
        },
        h2: {
            fontSize: "ArticleHeading2",
            marginTop: "ArticleHeading2",
            shouldSlugify: true,
        },
        h3: {
            fontSize: "ArticleHeading3",
            marginTop: "ArticleHeading3",
        },
        h4: {
            fontSize: "ArticleHeading4",
            marginTop: "ArticleHeading4",
        },
        h5: {
            fontSize: "ArticleHeading5",
            marginTop: "ArticleHeading5",
        },
        h6: {
            fontSize: "ArticleHeading6",
            marginTop: "ArticleHeading6",
        },
        li: {
            fontSize: "ArticleBody",
            themeKey: "textPrimary",
        },
        ol: {
            fontSize: "ArticleBody",
            marginBottom: "ArticleList",
            marginTop: "ArticleList",
        },
        p: {
            fontSize: "ArticleBody",
            marginBottom: "ArticleParagraphMargin",
            marginTop: "ArticleParagraphMargin",
            themeKey: "textPrimary",
        },
        strong: {
            fontSize: "ArticleBody",
            fontWeight: "medium",
        },
        table: {
            marginBottom: "ArticleLargeMargin",
            marginTop: "ArticleLargeMargin",
        },
        tbody: {},
        td: {
            className: css({
                backgroundColor: Colors["lighten-5"],
                border: `1px solid ${Colors["lighten-20"]}`,
                padding: "1rem",
            }),
            fontSize: "ArticleBody",
            /**
             * textAlign is passed in as a prop because
             * the markdown parser passes it through this way.
             * This way, it can override the default "center"
             */
            textAlign: "center",
        },
        /**
         * It might make more sense to move table border
         * radius and other specific styles from here
         * to the new table component
         */
        th: {
            className: css(
                {
                    backgroundColor: Colors["lighten-10"],
                    border: `1px solid ${Colors["lighten-20"]}`,
                    padding: "1rem",
                },
                {
                    "&:first-of-type": {
                        borderTopLeftRadius: BorderRadiuses["borderMedium"],
                    },
                    "&:last-of-type": {
                        borderTopRightRadius: BorderRadiuses["borderMedium"],
                    },
                },
            ),
            fontSize: "ArticleBody",
        },
        tr: {
            css: css({
                "&:last-of-type td:first-of-type": {
                    borderBottomLeftRadius: BorderRadiuses["borderMedium"],
                },
                "&:last-of-type td:last-of-type": {
                    borderBottomRightRadius: BorderRadiuses["borderMedium"],
                },
            }),
        },
        ul: {
            fontSize: "ArticleBody",
            marginBottom: "ArticleList",
            marginTop: "ArticleList",
        },
    },
    ArticleCaption: {
        a: {
            fontSize: "Micro",
            interactionType: "underline-disappear",
            themeKey: "linkSecondary",
        },
        code: {
            fontSize: "Micro",
        },
        em: {
            fontSize: "Micro",
        },
        ol: {
            fontSize: "Micro",
        },
        p: {
            fontSize: "Micro",
            textAlign: "center",
        },
        strong: {
            fontSize: "Micro",
            fontWeight: "medium",
            textAlign: "center",
        },
        ul: {
            fontSize: "Micro",
        },
    },
    CompareMatrixRowContent: {
        a: {
            fontSize: "CompareMatrixRowContent",
            interactionType: "underline-disappear",
            themeKey: "linkPrimary",
        },
        baseline: {
            shouldBalanceWrap: false,
        },
        em: {
            fontSize: "CompareMatrixRowContent",
            fontWeight: "medium",
            tag: "em",
            themeKey: "headlinePrimary",
        },
        ol: {
            fontSize: "CompareMatrixRowContent",
            marginTop: "LongformParagraph",
            themeKey: "headlinePrimary",
        },
        p: {
            fontSize: "CompareMatrixRowContent",
            marginTop: "LongformParagraph",
            themeKey: "headlinePrimary",
        },
        strong: {
            fontSize: "CompareMatrixRowContent",
            fontWeight: "medium",
            tag: "strong",
            themeKey: "headlinePrimary",
        },
        ul: {
            fontSize: "CompareMatrixRowContent",
            marginTop: "LongformParagraph",
            themeKey: "headlinePrimary",
        },
    },

    LegacyFeatureHero: {
        a: {
            fontSize: "LegacyFeaturesHeroSubheadline",
            interactionType: "underline-disappear",
            themeKey: "linkPrimary",
        },
        baseline: {
            shouldBalanceWrap: true,
        },
        p: {
            fontSize: "LegacyFeaturesHeroSubheadline",
            textAlign: "center",
            themeKey: "textPrimary",
        },
        strong: {
            fontSize: "LegacyFeaturesHeroSubheadline",
            fontWeight: "medium",
            themeKey: "textPrimary",
        },
    },

    LegacyFeatureHorizontal: {
        a: {
            fontSize: "LegacyFeatureHorizontalItemSubheadline",
            interactionType: "underline-disappear",
            themeKey: "linkPrimary",
        },
        ol: {
            fontSize: "LegacyFeatureHorizontalItemSubheadline",
            themeKey: "textPrimary",
        },
        p: {
            fontSize: "LegacyFeatureHorizontalItemSubheadline",
            themeKey: "textPrimary",
        },
        strong: {
            fontSize: "LegacyFeatureHorizontalItemSubheadline",
            fontWeight: "medium",
            themeKey: "textPrimary",
        },
        ul: {
            fontSize: "LegacyFeatureHorizontalItemSubheadline",
            themeKey: "textPrimary",
        },
    },

    LegacyFeatureVerticalItem: {
        a: {
            fontSize: "LegacyFeatureVerticalItemSubheadline",
            interactionType: "underline-disappear",
            themeKey: "linkPrimary",
        },
        ol: {
            fontSize: "LegacyFeatureVerticalItemSubheadline",
            themeKey: "textPrimary",
        },
        p: {
            fontSize: "LegacyFeatureVerticalItemSubheadline",
            themeKey: "textPrimary",
        },
        strong: {
            fontSize: "LegacyFeatureVerticalItemSubheadline",
            fontWeight: "medium",
            themeKey: "textPrimary",
        },
        ul: {
            fontSize: "LegacyFeatureVerticalItemSubheadline",
            themeKey: "textPrimary",
        },
    },

    LegacyPreFooter: {
        em: {
            fontSize: "TEXT_0875",
            fontWeight: "medium",
            themeKey: "textPrimary",
        },
        li: {
            fontSize: "ProductHeroSubheadline",
            iconSlug: "Check Circled",
            themeKey: "textPrimary",
        },
        ol: {
            className: css(
                {
                    columnGap: Spacing["spacing-6"],
                    columns: 2,
                    display: "grid",
                    listStylePosition: "inside",
                },
                buildStylesByBreakpoint("gridTemplateColumns", {
                    extraSmall: "1fr",
                    medium: "1fr 1fr",
                    large: "1fr",
                }),
            ),
        },
        p: { fontSize: "TEXT_0875", themeKey: "textPrimary" },
        strong: {
            fontSize: "TEXT_0875",
            fontWeight: "medium",
            themeKey: "textPrimary",
        },
        ul: {
            className: css(
                {
                    columnGap: Spacing["spacing-6"],
                    columns: 2,
                    display: "grid",
                    listStylePosition: "inside",
                },
                buildStylesByBreakpoint("gridTemplateColumns", {
                    extraSmall: "1fr",
                    medium: "1fr 1fr",
                    large: "1fr",
                }),
            ),
        },
    },

    Longform: {
        a: {
            fontSize: "LongformTextDefault",
            interactionType: "underline-disappear",
            themeKey: "linkPrimary",
        },
        baseline: {
            shouldBalanceWrap: false,
        },
        em: {
            fontSize: "LongformTextDefault",
            fontWeight: "medium",
            tag: "em",
            themeKey: "textPrimary",
        },
        h1: {
            fontSize: "LongformHeadlineLarge",
            marginTop: "LongformHeadlineLarge",
            tag: "h2",
        },
        h2: {
            fontSize: "LongformHeadlineDefault",
            marginTop: "LongformHeadlineLarge",
            tag: "h3",
        },
        h3: {
            fontSize: "LongformTextDefault",
            fontWeight: "medium",
            marginTop: "LongformHeadlineSmall",
            tag: "h4",
        },
        h4: {
            fontSize: "LongformTextDefault",
            fontWeight: "medium",
            marginTop: "LongformHeadlineSmall",
            tag: "h5",
        },
        ol: {
            fontSize: "LongformTextDefault",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
        p: {
            fontSize: "LongformTextDefault",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
        strong: {
            fontSize: "LongformTextDefault",
            fontWeight: "medium",
            tag: "strong",
            themeKey: "textPrimary",
        },
        ul: {
            fontSize: "LongformTextDefault",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
    },

    PricingPopover: {
        em: {
            fontSize: "TEXT_0875",
            fontWeight: "medium",
            themeKey: "textEmphasis",
        },
        p: {
            fontSize: "TEXT_0875",
            themeKey: "textSecondary",
        },
        strong: {
            className: css({
                display: "inline-block",
            }),
            fontSize: "TEXT_0875",
            fontWeight: "medium",
            marginBottom: "PopoverCardTitleMarginBottom",
            themeKey: "textEmphasis",
        },
    },

    PrivacyList: {
        a: {
            fontSize: "Default",
            themeKey: "linkPrimary",
        },
        p: {
            fontSize: "Default",
            themeKey: "textPrimary",
        },
    },

    PrivacyListSmall: {
        a: {
            fontSize: "Small",
            themeKey: "linkPrimary",
        },
        p: {
            fontSize: "Small",
            themeKey: "textPrimary",
        },
    },

    StateOfCLI2023BodyText: {
        a: {
            fontSize: "TEXT_125",
            fontWeight: "normal",
            interactionType: "underline-disappear",
            themeKey: "linkSecondary",
        },
        baseline: {
            shouldBalanceWrap: false,
        },
        em: {
            fontSize: "TEXT_125",
            tag: "em",
            themeKey: "textPrimary",
        },
        h1: {
            fontSize: "HeadlineSmall",
            marginTop: "AgentModeHeading2MarginTop",
            shouldSlugify: true,
            tag: "h2",
        },
        h2: {
            fontSize: "HeadlineSmall",
            marginTop: "AgentModeHeading3MarginTop",
            shouldSlugify: true,
            tag: "h3",
        },
        h3: {
            fontSize: "HeadlineSmall",
            marginTop: "AgentModeHeading3MarginTop",
            shouldSlugify: true,
            tag: "h4",
        },
        ol: {
            fontSize: "TEXT_125",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
        p: {
            fontSize: "TEXT_125",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
        strong: {
            fontSize: "TEXT_125",
            fontWeight: "medium",
            tag: "strong",
            themeKey: "textPrimary",
        },
        ul: {
            fontSize: "TEXT_125",
            marginTop: "LongformParagraph",
            themeKey: "textPrimary",
        },
    },

    StateOfCliKeyTakeawayDescription: {
        a: {
            fontSize: "TEXT_1",
            interactionType: "underline-disappear",
            themeKey: "linkPrimary",
        },
        baseline: {
            marginTop: "StateOfCliKeyTakeawayDescription",
            shouldBalanceWrap: false,
        },
        p: {
            fontSize: "TEXT_1",
            themeKey: "textPrimary",
        },
    },

    TerminusSidebarAdSecondaryText: {
        a: {
            fontSize: "TerminusSidebarAdSubheadline",
            interactionType: "underline-disappear",
            themeKey: "linkPrimary",
        },
        baseline: {
            shouldBalanceWrap: false,
        },
        p: {
            fontSize: "TerminusSidebarAdSubheadline",
        },
    },

    UiSubheadline: {
        a: {
            inheritFontSize: true,
            interactionType: "underline-disappear",
        },
        p: {
            fontSize: "Subheadline",
        },
    },
};
