/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiFooter } from "@/types/strapi";

import { Colors } from "@/tokens/color";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Icon } from "@/ui/atoms/icon";
import { Link } from "@/ui/atoms/link";
import { Logo } from "@/ui/atoms/logo";
import { StatusFlag } from "@/ui/atoms/status_flag";
import { Text } from "@/ui/atoms/text";

import { formatSocialIconSlug } from "@/util/data_util";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";

interface FooterProps extends StrapiFooter {
    className?: SerializedStyles;
    legacyGrid?: boolean;
    renderDivider?: boolean;
}

export const Footer: FunctionComponent<FooterProps> = ({
    legacyGrid = true,
    renderDivider = true,
    ...props
}) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const defaultLinkSpacing = Spacing["spacing-4"];

    const footerContainerStyles = css(
        {
            paddingBottom: Spacing["spacing-4"],
        },
        buildStylesByBreakpoint("paddingTop", {
            extraSmall: Spacing["spacing-6"],
            medium: Spacing["spacing-8"],
        }),
        props.className,
    );

    const supplementalColumnContainerStyles = css(
        {
            display: "flex",
            height: "max-content",
            justifyContent: "space-between",
        },
        buildStylesByBreakpoint("gap", {
            medium: Spacing["spacing-8"],
            large: Spacing["spacing-12"],
        }),
        buildStylesByBreakpoint("flexDirection", {
            extraSmall: "row",
            medium: "column",
        }),
        buildStylesByBreakpoint("alignItems", {
            extraSmall: "center",
            medium: "flex-start",
        }),
        buildStylesByBreakpoint("marginBottom", {
            extraSmall: Spacing["spacing-12"],
            medium: 0,
        }),
    );

    const linkGroupContainerStyles = css(
        { display: "flex" },
        buildStylesByBreakpoint("flexDirection", {
            extraSmall: "column",
            medium: "row",
        }),
        buildStylesByBreakpoint("gap", ColumnGaps),
        buildStylesByBreakpoint("flexWrap", {
            extraSmall: "nowrap",
            medium: "wrap",
            large: "nowrap",
        }),
    );

    const baseListStyles = css({
        display: "flex",
        flexBasis: 1,
        flexDirection: "column",
        flexGrow: 1,
        gap: defaultLinkSpacing,
    });

    const linkListStyles = css({
        display: "flex",
        flexBasis: 1,
        flexDirection: "column",
        flexGrow: 1,
    });

    const primaryLinkStyles = (_index: number, _arrayLength: number) => {
        return css({
            paddingBottom:
                _index < _arrayLength - 1
                    ? `calc(${defaultLinkSpacing} / 2)`
                    : undefined,
            paddingTop:
                _index > 0 ? `calc(${defaultLinkSpacing} / 2)` : undefined,
        });
    };

    const bottomColumnStyles = css(
        {
            alignItems: "center",
            borderTop: `1px solid ${Colors["lighten-30"]}`,
            display: "flex",
            justifyContent: "space-between",
            rowGap: Spacing["spacing-5"],
        },
        buildStylesByBreakpoint("flexDirection", {
            extraSmall: "column-reverse",
            medium: "row",
        }),
        buildStylesByBreakpoint("marginTop", {
            extraSmall: Spacing["spacing-12"],
            medium: Spacing["spacing-32"],
            large: Spacing["spacing-40"],
        }),
        buildStylesByBreakpoint("paddingTop", {
            extraSmall: Spacing["spacing-6"],
            medium: Spacing["spacing-4"],
        }),
    );

    const socialLinkStyles = css(
        {
            alignItems: "center",
            display: "flex",
            gap: Spacing["spacing-4"],
        },
        buildStylesByBreakpoint("justifyContent", {
            extraSmall: "center",
            medium: "flex-end",
        }),
    );

    const logoStyles = css({
        objectFit: "contain",
        width: Spacing["spacing-8"],
    });

    /**
     * Rendering
     */
    const renderLinkGroups = () => (
        <GridColumn
            className={linkGroupContainerStyles}
            columnSpan={{
                extraSmall: "full",
                medium: 9,
                large: 6,
            }}
            columnStart={{
                extraSmall: 1,
                medium: 4,
                large: 7,
            }}
        >
            {props?.Link_Groups.map(({ Links, Title }) => (
                <div css={baseListStyles} key={`footer-link-group::${Title}`}>
                    <Text
                        fontSize="TEXT_125"
                        fontWeight="medium"
                        labelLineHeight={true}
                        themeKey="headlinePrimary"
                    >
                        {Title}
                    </Text>

                    <ul css={linkListStyles}>
                        {Links.map(({ Text, URL }, _index, _arr) => (
                            <li
                                key={`footer-link-group-link::${Title}::${Text}`}
                            >
                                <Link
                                    renderAsBlock
                                    className={primaryLinkStyles(
                                        _index,
                                        _arr.length,
                                    )}
                                    fontSize="TEXT_1"
                                    fontWeight="normal"
                                    href={URL}
                                    interactionType="underline-appear"
                                    labelLineHeight={true}
                                    themeKey="linkSecondary"
                                >
                                    {Text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </GridColumn>
    );

    const renderSocialLinks = () =>
        props?.Social_Links.map(({ Text, URL }) => {
            return (
                <div css={baseListStyles} key={`footer-social-link::${Text}`}>
                    <Link ariaLabel={Text} href={URL}>
                        <Icon
                            color={theme.text.textSecondary}
                            slug={formatSocialIconSlug(Text)}
                            weight="light"
                        />
                    </Link>
                </div>
            );
        });

    const renderFooterBottom = () => (
        <GridColumn className={bottomColumnStyles}>
            <Text
                isUppercase
                fontFamily="mono"
                fontSize="TEXT_075"
                themeKey="textSecondary"
            >
                All Rights Reserved © {new Date().getFullYear()}
            </Text>

            <div css={socialLinkStyles}>{renderSocialLinks()}</div>
        </GridColumn>
    );

    const renderLargeFooter = () => {
        return (
            <>
                <GridColumn
                    className={supplementalColumnContainerStyles}
                    columnSpan={{
                        extraSmall: "full",
                        medium: 3,
                        large: 6,
                    }}
                >
                    <Logo glyphOnly className={logoStyles} />

                    <StatusFlag />
                </GridColumn>

                {renderLinkGroups()}

                {renderFooterBottom()}
            </>
        );
    };

    return (
        <>
            {renderDivider && <Divider />}

            <GridContainer
                containerClassName={footerContainerStyles}
                legacyGrid={legacyGrid}
                tag="footer"
            >
                {renderLargeFooter()}
            </GridContainer>
        </>
    );
};

Footer.displayName = "Footer";
