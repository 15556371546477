/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import gsap, { Expo } from "gsap";
import NextLink from "next/link";
import {
    type MouseEventHandler,
    type RefObject,
    forwardRef,
    useEffect,
    useRef,
} from "react";

import { AnalyticsTrack } from "@/types/analytics";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { TransitionEasings, TransitionSpeeds } from "@/tokens/motion";
import { Spacing } from "@/tokens/spacing";

import { formatUrl } from "@/util/data_util";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { useRudderAnalyticsTrack } from "@/util/hooks/use_rudder_analytics";
import { buildStylesByBreakpoint } from "@/util/style_util";

import { Icon } from "./icon";
import { Text } from "./text";

interface NavLinkProps {
    className?: SerializedStyles;
    expandable?: boolean;
    isActive?: boolean;
    isNested?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    onHover?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    text: string;
    track?: AnalyticsTrack;
    url?: string;
}

export const NavLink = forwardRef<
    HTMLAnchorElement | HTMLButtonElement,
    NavLinkProps
>((props, ref) => {
    /**
     * Variables
     */
    const theme = useTypedTheme();

    /**
     * Refs
     */
    const caretRef = useRef(null);

    /**
     * Interactivity
     */

    const track = useRudderAnalyticsTrack();

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (props.track) {
            track(props.track);
        }

        props.onClick && props.onClick(event);
    };

    /**
     * Animations
     */
    // Rotates the caret on expansion
    useEffect(() => {
        if (caretRef.current) {
            gsap.to(caretRef.current, {
                duration: 0.25,
                ease: Expo.easeOut,
                rotate: props.isActive ? 90 : 0,
                y: props.isActive ? -1 : 0,
            });
        }
    }, [props.isActive]);

    /**
     * Styles
     */
    const _paddingStyles = props.expandable
        ? buildStylesByBreakpoint("padding", {
              extraSmall: `${Spacing["spacing-2"]} ${Spacing["spacing-2"]} ${Spacing["spacing-2"]} ${Spacing["spacing-3"]}`,
              large: `${Spacing["spacing-3"]} ${Spacing["spacing-2"]} ${Spacing["spacing-3"]} ${Spacing["spacing-3"]}`,
          })
        : buildStylesByBreakpoint("padding", {
              extraSmall: `${Spacing["spacing-2"]} ${Spacing["spacing-3"]}`,
              large: `${Spacing["spacing-3"]} ${Spacing["spacing-3"]}`,
          });

    const baseLinkStyles = css(
        {
            alignItems: "baseline",
            appearance: "none",
            background: "transparent",
            border: "none",
            borderRadius: BorderRadiuses.borderMedium,
            columnGap: Spacing["spacing-1"],
            cursor: "pointer",
            display: "flex",
            textDecoration: "none",
            transition: `background ${TransitionSpeeds.fast}ms ${TransitionEasings.linear}`,
        },
        {
            "&:active": {
                background:
                    Colors[theme.buttons.buttonPrimary.background.active],
            },
            "&:hover": {
                background: Colors["lighten-10"],
            },
        },
        _paddingStyles,
        props.className,
    );

    const caretStyles = css({
        transformOrigin: "center",
    });

    /**
     * Rendering
     */
    const renderLink = () => {
        return (
            <>
                <Text
                    fontSize="NavText"
                    fontWeight="medium"
                    labelLineHeight={true}
                    tag="span"
                    themeKey="textEmphasis"
                >
                    {props.text}
                </Text>

                {props.expandable && (
                    <Icon
                        className={caretStyles}
                        color={theme.ui.uiInactive}
                        ref={caretRef}
                        size={{
                            extraSmall: 10,
                            large: 12,
                        }}
                        slug="Caret Right"
                    />
                )}
            </>
        );
    };

    return props.expandable ? (
        <button
            css={baseLinkStyles}
            ref={ref as RefObject<HTMLButtonElement>}
            onClick={props.onClick}
            onMouseEnter={props.onHover}
        >
            {renderLink()}
        </button>
    ) : (
        // Base path is fallback
        <NextLink
            css={baseLinkStyles}
            href={formatUrl(props.url ?? "/")}
            ref={ref as RefObject<HTMLAnchorElement>}
            onClick={handleClick}
            onMouseEnter={props.onHover}
        >
            {renderLink()}
        </NextLink>
    );
});

NavLink.displayName = "NavLink";
